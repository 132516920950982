import React, {useCallback, useEffect, useState} from 'react'
import {Row, Table, Typography} from 'antd'
import b_ from 'b_'
import TableSettings from "../../Components/TableSettings"
import useStorageState from '../../Utils/useStorageState'
import PrintPayoutState from "../../Components/Prints/PrintPayoutState";
import {useDispatch} from "react-redux";
import PrintDate from "../../Components/Prints/PrintDate";
import {Link} from "react-router-dom";
import {JSONTree} from "react-json-tree";
import theme from "../../Utils/jsonTheme";
import PrintSendStatus from "../../Components/Prints/PrintSendStatus";
import MarkDone from "./MarkDone";
import Cancel from "./Cancel";
import Retry from "./Retry";

const b = b_.lock('PayoutsTable')
const {Paragraph} = Typography


function PayoutsTable({
                        list,
                        tableOnChange,
                        pagination,
                        isLoading,
                        afterUpdate,
                        onFilter,
                        filters,
                        sorter,
                        selected,
                        onSelectChange
                      }) {

  const dispatch = useDispatch();

  const GetColumns = useCallback(() => {

    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        fixed: 'left',
      },
      {
        title: 'User',
        dataIndex: 'userEmail',
        key: 'userEmail',
        fixed: 'left',
        width: 200,
        render: (id, {userEmail}) => <Link
          to={`/users?limit=50&offset=0&filters[email][]=${userEmail}`}>{userEmail}</Link>
      },
      {
        title: 'Created',
        dataIndex: 'createdTs',
        key: 'createdTs',
        width: 200,
        render: (ts) => <PrintDate withTime date={ts}/>,
      },
      {
        title: 'Payout Type',
        dataIndex: 'payoutType',
        key: 'payoutType',
        width: 120,
      },
      {
        title: 'Last Changed',
        dataIndex: 'lastChangedTs',
        key: 'lastChangedTs',
        width: 200,
        render: (ts) => <PrintDate withTime date={ts}/>,
      },
      {
        title: 'Wallet, BTC',
        dataIndex: 'walletBtc',
        key: 'walletBtc',
        width: 150,
      },
      {
        title: 'Wallet',
        dataIndex: 'walletUsd',
        key: 'walletUsd',
        width: 100,
        render: (value, {userEmail, amountCurrency}) =>
          <Link to={`/transactions?limit=50&offset=0&filters[userEmail][]=${userEmail}`}>{value} {amountCurrency}</Link>
      },
      {
        title: 'Exch., USD',
        dataIndex: 'exchangeUsd',
        key: 'exchangeUsd',
        width: 100,
      },
      {
        title: 'Profit, USD',
        dataIndex: 'profitUsd',
        key: 'profitUsd',
        width: 100,
      },
      {
        title: 'Wallet Fee, USD',
        dataIndex: 'walletFeeUsd',
        key: 'walletFeeUsd',
        width: 100,
      },
      {
        title: "State",
        dataIndex: "state",
        align: "center",
        width: 150,
        render: (state) => <PrintPayoutState state={state}/>
      },
      {
        title: 'BTC Wallet',
        dataIndex: 'walletAddr',
        key: 'walletAddr',
        width: 200,
        render: (value, rec) =>
          value && <a href={`https://blockchain.info/address/${value}`} rel="noreferrer noopener"
                      target="_blank">{value.substr(0, 10) + '...'}</a>
      },
      {
        title: 'Bank Name',
        dataIndex: 'bankName',
        key: 'bankName',
        width: 100,
      },
      {
        title: 'First Name',
        dataIndex: 'bankAccountFirstName',
        key: 'bankAccountFirstName',
        width: 100,
      },
      {
        title: 'Second Name',
        dataIndex: 'bankAccountSecondName',
        key: 'bankAccountSecondName',
        width: 100,
      },
      {
        title: 'Account Number',
        dataIndex: 'bankAccountNumber',
        key: 'bankAccountNumber',
        width: 100,
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
        width: 170,
      },
      {
        title: 'Result',
        dataIndex: 'tx',
        key: 'tx',
        width: 300,
        render: (value, rec) =>
          <>
            {value && <Row><a href={`https://blockchain.info/tx/${value}`} rel="noreferrer noopener"
                              target="_blank">{value.substr(0, 20) + '...'}</a></Row>}
            {rec.message && <Row>{`${rec.message}`}</Row>}
          </>
      },
      {
        title: "Send Status",
        dataIndex: "sendStatus",
        key: "sendStatus",
        align: "center",
        width: 100,
        render: (status) => status && <PrintSendStatus status={status}/>
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 100,
        render: (_, rec) =>
          <div className="actionButtonsGroup">
            {rec.showRetry && <Retry payoutId={rec.id} afterUpdate={afterUpdate} withOrderId={rec.retryWithOrderId}/>}
            {rec.showCancel && <Cancel payoutId={rec.id} afterUpdate={afterUpdate}/>}
            {rec.showDone && <MarkDone payoutId={rec.id} afterUpdate={afterUpdate}/>}
          </div>
      },
    ];
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());

  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('payouts_table', {
    checkedList: Checkboxs,
    indeterminate: false,
    checkAll: true,
  });

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < columns.length,
      checkAll: checkedList.length === columns.length,
    });
  }, [Checkboxs]);

  useEffect(() => {
    let allCheckList = []
    if (list.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
        allCheckList.push(e[Object.keys(e)[1]])
        setCheckbox(allCheckList)
      })
    }
  }, [list, AllCheckbox, Checkboxs.length, GetColumns])

  useEffect(() => {
    if (AllCheckbox.checkAll) {
      setAllCheckbox({
        checkedList: GetColumns().map(e => e.title),
        indeterminate: false,
        checkAll: true,
      })
    }
  }, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
      checkedList: (e.target.checked && columns.map(e => e.title)) || [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  }, [Checkboxs]);

  useEffect(() => {
    setColumns(GetColumns())
  }, [list, isLoading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  // const loader = Object.keys(loadings).some(e => !!loadings[e]) || loading
  return <div className={b()}>
    {/*<CodeFilters filters={filters} onFilter={onFilter} className="mb"/>*/}
    <Table
      className={b()}
      bordered
      dataSource={list}
      scroll={{x: "max-content"}}
      size="middle"
      onChange={(a, b, c) => tableOnChange(a, b, c)}
      onFilter={onFilter}
      pagination={pagination}
      loading={isLoading}
      columns={columnsFilter}
      rowClassName={() => b('lock')}
      rowKey="id"
      rowSelection={{
        selectedRowKeys: selected,
        onChange: onSelectChange,
      }}
      title={() => <TableSettings
        onChangeCheckAll={onChangeCheckAll}
        allChecks={AllCheckbox}
        Checkboxs={AllCheckbox.checkedList}
        TableColumn={() => GetColumns()}
        setCheckbox={onChangeCheckList}
      />}
    />
  </div>
}

export default PayoutsTable;
